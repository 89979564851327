import React, {useEffect, useState} from "react"
import moment from "moment/moment";
import {clone, cloneDeep, find, isNull, truncate} from "lodash";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {Archive, Check, Clear, DeleteOutline, MoreHoriz, Summarize, Visibility} from "@mui/icons-material";
import {Box, Button, Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Stack, SvgIcon} from "@mui/material";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";

import {useStores} from "models";
import {BasicTable, MenuIcon, ModalConfirm} from "components";
import {globalAction, optionsTranslation} from "utils";
import {IconEdit} from "assets/images";
import {findRoute} from "constants/routes";
import {ApproveReject, ApproveRejectButton} from "modules";
import {formatDate} from "constants/form"
import { saveString } from 'utils/storage';
import { HEADER_SELECTOR_SAMPLE_STORAGE_KEY } from 'config/env';

import {EmptySamples} from "../index";
import './sample-table.css';

const columnHelper = createColumnHelper<any>()

const RenderMenuItems = observer((props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    cuppingSessionStore: { getCuppingSession },
    sampleStore: { setValue: setSampleValue },
    reviewSampleStore: { samples, removeSample },
    notificationStore
  } = useStores()
  const {
    id, uniqueToken, cuppingSessionUniqueToken, canSeeReport, canDeleteSample
  } = props.row
  const { isReview, setOpenConfirm, setConfirmAction, setDescription } = props

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)

  const onAction = (token: string, id: string, link: string) => {
    globalAction(notificationStore, {
      action: async () => await getCuppingSession(token),
      afterAction: () => {
        setSampleValue('selectedSample', id)
        navigate(link)
      }
    })
  }

  const onSeeReport = async (token: string, id: string) => {
    if (!isReview) {
      navigate(`/sample-report/${token}`)
      return
    }

    const sample = find(samples, ['id', id])
    if (!sample) return

    onAction(token, id, `/sample-report/${sample.uniqueToken}`)
  }

  const onRemoveSample = async (id: string, isFully = false) => {
    setOpenConfirm(false)
    globalAction(notificationStore, {
      action: async () => await removeSample(id, isFully)
    })
  }

  if (!isReview) {
    return (
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => onSeeReport(uniqueToken, id)}
        startIcon={<Summarize />}
      >
        {t('sample.report')}
      </Button>
    )
  }

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        onClick={(event) => setAnchorMenu(event.currentTarget)}
      >
        <MoreHoriz />
      </IconButton>

      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={() => setAnchorMenu(null)}
        onClick={() => setAnchorMenu(null)}
      >
        <MenuItem onClick={() => navigate(`/samples/${uniqueToken}/details`)}>
          <MenuIcon><Visibility /></MenuIcon>
          {t('sample.samples.button.detail')}
        </MenuItem>
        <MenuItem onClick={() => navigate(`/samples/${uniqueToken}`)}>
          <MenuIcon><SvgIcon component={IconEdit} inheritViewBox /></MenuIcon>
          {t('common.edit')}
        </MenuItem>
        <MenuItem
          disabled={!canSeeReport}
          onClick={() => {
            isReview ?
              onSeeReport(cuppingSessionUniqueToken, id) :
              onSeeReport(uniqueToken, id)
          }}
        >
          <MenuIcon><Summarize /></MenuIcon>
          {t('sample.report')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenConfirm(true)
            setConfirmAction(() => () => onRemoveSample(id))
            setDescription(t('sample.removeSampleDescription'))
          }}
        >
          <MenuIcon><Archive /></MenuIcon>
          {t('sample.archive')}
        </MenuItem>
        <MenuItem
          disabled={!canDeleteSample}
          onClick={() => {
            setOpenConfirm(true)
            setConfirmAction(() => () => onRemoveSample(id, true))
            setDescription(t('sample.removeSampleDescription'))
          }}
        >
          <MenuIcon><DeleteOutline /></MenuIcon>
          {t('common.delete')}
        </MenuItem>
      </Menu>
    </>
  )
})

export const SampleTable = observer((props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    reviewSampleStore: {
      samples, page, totalPage, setValue: setReviewSampleValue,
    },
  } = useStores()

  const [selectedSamples, setSelectedSamples] = useState<any[]>([])
  const [rowSelection, setRowSelection] = useState({})
  const [openConfirm, setOpenConfirm] = useState(false)
  const [confirmAction, setConfirmAction] = useState(() => () => {})
  const [description, setDescription] = useState('')
  const [sampleId, setSampleId] = useState('')
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openQcReason, setOpenQcReason] = useState(false)

  const navigateToSampleDetail = (uniqueToken: string) => navigate(`/samples/${uniqueToken}/details`)

  const handleRightClick = (event, uniqueToken) => {
    event.preventDefault(); // Prevent the default browser context menu
    const url = `/samples/${uniqueToken}/details`;
    if (url) {
      window.open(url, '_blank'); // Open the URL in a new tab
    }
  };

  const renderNavigationWrapper = (info, value, sx?) => {
    return (
      <Box
        onClick={() => navigateToSampleDetail(info.row.original.uniqueToken)}
        onContextMenu={(event)=>handleRightClick(event, info.row.original.uniqueToken)}
        sx={{
          cursor: 'pointer',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          ...sx
        }}
      >
        {value}
      </Box>
    )
  }

  const renderButtons = (info) => {
    const { uniqueToken } = info.row.original

    return (
      <Stack direction="row" justifyContent="center" spacing={1}>
        {props.isReview && (
          <ApproveRejectButton
            row={info.row.original}
            approveChildren={<Check />}
            onApprove={(hasApproval) => {
              setSampleId(uniqueToken)
              if (hasApproval) setOpenQcReason(true)
              setOpenConfirmApprove(true)
            }}
            rejectChildren={<Clear />}
            onReject={(hasApproval) => {
              setSampleId(uniqueToken)
              if (hasApproval) setOpenQcReason(true)
              setOpenRejectModal(true)
            }}
          />
        )}

        <RenderMenuItems
          row={info.row.original}
          isReview={props.isReview}
          setOpenConfirm={setOpenConfirm}
          setConfirmAction={setConfirmAction}
          setDescription={setDescription}
        />
      </Stack>
    )
  }

  const columns = [
    ...(props.isReview ? [
      columnHelper.accessor('sampleUniqueNumber', {
        id: 'checkboxSampleUniqueNumber',
        header: () => <Box textAlign="right" ml="30px"></Box>,
        cell: info =>
          <Box>
            <FormControlLabel
              label={""}
              control={
                <Checkbox
                  checked={info.row.getIsSelected()}
                  onChange={info.row.getToggleSelectedHandler()}
                />
              }
              sx={{marginRight: 0}}
            />
          </Box>,
      })
    ] : []),
    ...(props.isReview ? [
      columnHelper.accessor('sampleUniqueNumber', {
        id: 'sampleUniqueNumber',
        header: () => <Box textAlign="left">{t('sample.samples.id')}</Box>,
        cell: info =>
          renderNavigationWrapper(info, info.renderValue()),
      })
    ] : []),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <Box textAlign="left">{t('sample.samples.name')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue()),
    }),
    columnHelper.accessor('purchaseGrade', {
      id: 'purchaseGrade',
      header: () => <Box textAlign="left">{t('sample.purchaseGrade')}</Box>,
      cell: info => renderNavigationWrapper(info, truncate(info.renderValue(),{'length': 20, 'separator': ''}) || '-'),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <Box textAlign="left">{t('sample.samples.createdOn')}</Box>,
      cell: info => renderNavigationWrapper(info, moment(info.renderValue()).format(formatDate)),
    }),
    columnHelper.accessor('species', {
      id: 'species',
      header: () => <Box textAlign="left">{t('sample.samples.species')}</Box>,
      cell: info => renderNavigationWrapper(info, optionsTranslation('cuppingProtocol', info.renderValue())),
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('sample.samples.type')}</Box>,
      cell: info => renderNavigationWrapper(info, optionsTranslation('sampleType', info.renderValue())),
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: () => <Box textAlign="left">{t('sample.samples.origin')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue()),
    }),
    columnHelper.accessor('processName', {
      id: 'processName',
      header: () => <Box textAlign="left">{t('sample.samples.process')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue()),
    }),
    columnHelper.accessor('purchaseContractReference', {
      id: 'purchaseContractReference',
      header: () => <Box textAlign="left">{t('sample.samples.purchaseContract')}</Box>,
      cell: info => <Link to={`/contracts/${info.renderValue()}/details`} >{info.renderValue()}</Link>,
    }),
    columnHelper.accessor('salesContractReference', {
      id: 'salesContractReference',
      header: () => <Box textAlign="left">{t('sample.samples.salesContract')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('referenceNumber', {
      id: 'referenceNumber',
      header: () => <Box textAlign="left">{t('sample.referenceNumber')}</Box>,
      cell: info => renderNavigationWrapper(info, truncate(info.renderValue(),{'length': 20, 'separator': ''}) || '-'),
    }),
    columnHelper.accessor('grade', {
      id: 'grade',
      header: () => <Box textAlign="left">{t('sample.sampleGrade')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('supplierName', {
      id: 'supplierName',
      header: () => <Box textAlign="left">{t('sample.supplier')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('producerName', {
      id: 'producerName',
      header: () => <Box textAlign="left">{t('sample.producerName')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('strategy', {
      id: 'strategy',
      header: () => <Box textAlign="left">{t('sample.strategy')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('customer', {
      id: 'customer',
      header: () => <Box textAlign="left">{t('sample.customer')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('customerCode', {
      id: 'customerCode',
      header: () => <Box textAlign="left">{t('sample.customerCode')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('receivedOn', {
      id: 'receivedOn',
      header: () => <Box textAlign="left">{t('sample.receivedOn')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('dateDispatch', {
      id: 'dateDispatch',
      header: () => <Box textAlign="left">{t('sample.dateDispatch')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('dateArrival', {
      id: 'dateArrival',
      header: () => <Box textAlign="left">{t('sample.dateArrival')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('dateResults', {
      id: 'dateResults',
      header: () => <Box textAlign="left">{t('sample.dateResults')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('harvest', {
      id: 'harvest',
      header: () => <Box textAlign="left">{t('sample.harvest')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('externalIdentification', {
      id: 'externalIdentification',
      header: () => <Box textAlign="left">{t('sample.externalIdentification')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('sampleReference', {
      id: 'sampleReference',
      header: () => <Box textAlign="left">{t('sample.sampleReference')}</Box>,
      cell: info => renderNavigationWrapper(info, truncate(info.renderValue(),{'length': 20, 'separator': ''}) || '-'),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => <Box textAlign="left">{t('sample.description')}</Box>,
      cell: info => renderNavigationWrapper(info, truncate(info.renderValue(),{'length': 20, 'separator': ''}) || '-'),
    }),
    columnHelper.accessor('varietalsTags', {
      id: 'varietalsTags',
      header: () => <Box textAlign="left">{t('sample.varietalsTags')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('certification', {
      id: 'certification',
      header: () => <Box textAlign="left">{t('sample.certification')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('cuppingProtocol', {
      id: 'cuppingProtocol',
      header: () => <Box textAlign="left">{t('sample.cuppingProtocol')}</Box>,
      cell: info => renderNavigationWrapper(info, optionsTranslation('cuppingProtocol', info.renderValue()) || '-' ),
    }),
    columnHelper.accessor('receivedWeight', {
      id: 'receivedWeight',
      header: () => <Box textAlign="left">{t('sample.receivedWeight')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('cropYear', {
      id: 'cropYear',
      header: () => <Box textAlign="left">{t('sample.cropYear')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('numberOfBag', {
      id: 'numberOfBag',
      header: () => <Box textAlign="left">{t('sample.numberOfBags')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('bagWeight', {
      id: 'bagWeight',
      header: () => <Box textAlign="left">{t('sample.bagWeight')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('moisture', {
      id: 'moisture',
      header: () => <Box textAlign="left">{t('sample.moisture')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('waterActivity', {
      id: 'waterActivity',
      header: () => <Box textAlign="left">{t('sample.waterActivity')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('density', {
      id: 'density',
      header: () => <Box textAlign="left">{t('sample.density')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('temperature', {
      id: 'temperature',
      header: () => <Box textAlign="left">{t('sample.temperature')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('mass', {
      id: 'mass',
      header: () => <Box textAlign="left">{t('sample.mass')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('volume', {
      id: 'volume',
      header: () => <Box textAlign="left">{t('sample.volume')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('warehouseReference', {
      id: 'warehouseReference',
      header: () => <Box textAlign="left">{t('sample.warehouseReference')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('sample.sampleLocation')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('shipmentDate', {
      id: 'shipmentDate',
      header: () => <Box textAlign="left">{t('sample.shipmentDate')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-'),
    }),
    columnHelper.accessor('cargoNumber', {
      id: 'cargoNumber',
      header: () => <Box textAlign="left">{t('sample.cargoSeel')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('containerNumber', {
      id: 'containerNumber',
      header: () => <Box textAlign="left">{t('sample.containerNumber')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('lotNumberOrIcoMarks', {
      id: 'lotNumberOrIcoMarks',
      header: () => <Box textAlign="left">{t('sample.lotNumberOrIcoMarks')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('courierName', {
      id: 'courierName',
      header: () => <Box textAlign="left">{t('sample.courier')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('trackingNumber', {
      id: 'trackingNumber',
      header: () => <Box textAlign="left">{t('sample.trackingNumber')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('notesAndRemarks', {
      id: 'notesAndRemarks',
      header: () => <Box textAlign="left">{t('sample.notesAndRemarks')}</Box>,
      cell: info => renderNavigationWrapper(info, info.renderValue() || '-'),
    }),
    columnHelper.accessor('averageScore', {
      id: 'averageScore',
      header: () => t('sample.samples.averageScore'),
      cell: info => renderNavigationWrapper(
        info,
        (info.renderValue() === 0 || isNull(info.renderValue())) ? "-" : info.renderValue(),
        {justifyContent: "center"}
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => t('sample.samples.action'),
      cell: info => renderButtons(info),
    }),
  ]

  const visibleColumns = React.useMemo(() => {
    const visibleKeys = props.tmpHeaderSelectorSamples
      .filter((item) => item.value)
      .map((item) => item.key);

    if(visibleKeys.length !== 0 ) saveString(HEADER_SELECTOR_SAMPLE_STORAGE_KEY, JSON.stringify(props.tmpHeaderSelectorSamples))

    return columns.filter((col) => visibleKeys.includes(col.id!));
  }, [props.tmpHeaderSelectorSamples]);

  const table = useReactTable({
    data: cloneDeep(samples),
    columns: visibleColumns,
    state: { rowSelection },
    enableRowSelection: true,
    manualPagination: true,
    pageCount: totalPage,
    autoResetPageIndex: false,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.uniqueToken,
  })

  useEffect(() => {
    const tokens = Object.keys(rowSelection)
    const newSelectedSamples = tokens.map(token => {
      let sample = find(selectedSamples, ['uniqueToken', token])

      if (!sample) {
        sample = find(samples, ['uniqueToken', token])
      }

      return clone(sample)
    })

    setSelectedSamples(newSelectedSamples)
    props.onSelected(newSelectedSamples)
  }, [rowSelection])

  useEffect(() => {
    const selectedRow = {}
    props.reservedSamples.forEach(sample => {
      selectedRow[sample.uniqueToken] = true
    })

    setRowSelection(selectedRow)
  }, [props.reservedSamples.length])

  useEffect(() => {
    props.onGetSamples()
  }, [page])

  if (samples.length === 0) return <EmptySamples />

  return (
    <Box mt={2}>
      <BasicTable
        table={table}
        page={page}
        totalPage={totalPage}
        onChangePage={(e, p) => setReviewSampleValue('page', p)}
        sx={{ maxHeight: window.innerHeight - 300 }}
        tableProps={{
          stickyHeader: true,
        }}
      />

      <ModalConfirm
        open={openConfirm}
        description={description}
        onClose={() => setOpenConfirm(false)}
        onConfirm={confirmAction}
      />

      <ApproveReject
        id={sampleId}
        isSample
        openQcReason={openQcReason}
        setOpenQcReason={setOpenQcReason}
        openConfirmApprove={openConfirmApprove}
        setOpenConfirmApprove={setOpenConfirmApprove}
        openRejectModal={openRejectModal}
        setOpenRejectModal={setOpenRejectModal}
        onSuccess={props.onGetSamples}
      />
    </Box>
  )
})
