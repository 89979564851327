/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
import { useStores } from 'models'
import {cloneDeep, concat, isEmpty} from "lodash";

import { findRoute } from 'constants/routes'
import { HeaderTitleWide } from 'components'

import {useStyles} from "./cupping-session-snapshot.styles";
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {colors} from "../../assets";

export const CuppingSessionsSnapshot: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const params = useParams()
  const locations = useLocation();
  const { state } = locations;

  const sampleLink = findRoute('reviewSamples')
  const sampleInformationLink = findRoute('sampleInformation')
  const sampleReviewLink = findRoute('sampleReview')

  const {
    sampleStore: { getSamples,samples },
    userStore: { isAuthenticated }
  } = useStores()

  const [loading, setLoading] = React.useState(false)
  const [tempSamples, setTempSamples] = React.useState<any[]>([])

  const snapshotConfiguration = state ? (state as any).snapshotStore : undefined;

  const checkReportConfiguration = (att: string) => {
    if (!snapshotConfiguration) {
      return true;
    }

    return snapshotConfiguration[att] === true;
  }

  const makeComma = (arr) => {
    if (arr.length === 1) return arr[0]
    const firsts = arr.slice(0, arr.length)
    return firsts.join(', ')
  }

  const sumTmpSample = (id) => {
    return tempSamples.filter( x => x.sampleUniqueNumber === id).length
  }

  const setTmpSample = (data) =>{
    let no = 1
    data.map((samples, index) => {
      samples.scores.filter( data => data.isSelected).map((sc,i) => {
        if(!sc.isSelected) return
        setTempSamples( tempSamples => [
          ...tempSamples,
          {
            "no": no++,
            "id":i === 0 ? samples.sampleUniqueNumber: "",
            "sampleUniqueNumber":samples.sampleUniqueNumber,
            "sampleName": samples.name ,
            "sampleReference": samples.sampleReference,
            "purchaseContract": samples.purchaseContractReference,
            "salesContract": samples.salesContractReference,
            "purchaseGrade": samples.purchaseGrade,
            "sampleType": samples.sampleType,
            "cupper": isEmpty(sc.cupperName) ? t('report.guestCupper'): sc.cupperName,
            "totalSecore": sc.totalScore,
            "des": makeComma(concat(sc.acidityDescriptors,
              sc.aftertasteDescriptors, sc.balanceDescriptors, sc.bodyDescriptors, sc.defectsDescriptors, sc.flavorDescriptors,
              sc.fragranceDescriptors, sc.saltDescriptors, sc.mouthfeelDescriptors, sc.cleanCupDescriptors,
              sc.fragranceAromaDescriptors, sc.flavorAftertasteDescriptors, sc.tastingDescriptors, sc.sweetnessDescriptors)),
            "notes": sc.notes,
            "purchaseContractReference": samples.purchaseContractReference,
            "flag": "cupper"
          }])
      })

      if(isEmpty(samples.cuppingResultAggregate.totalScore.toString()) || samples.cuppingResultAggregate.totalScore === 0) return

      setTempSamples( tempSamples => [
        ...tempSamples,
        {
          "no": no++,
          "id":"",
          "sampleUniqueNumber":samples.sampleUniqueNumber,
          "sampleName": "",
          "sampleReference": "",
          "purchaseContract": "",
          "salesContract": "",
          "purchaseGrade":"",
          "sampleType": "",
          "cupper":<Typography variant="body2" sx={{fontWeight: 600}}> {t('report.officialScore')}</Typography>,
          "totalSecore": samples.cuppingResultAggregate.totalScore,
          "des": <Typography variant="body2" sx={{fontWeight: 600}}>{t('report.officialDescriptor')}</Typography>,
          "notes": <Typography variant="body2" sx={{fontWeight: 600}}>{t('report.internalNote')}</Typography>,
          "flag": "officialScore"
        }])

      setTempSamples( tempSamples => [
        ...tempSamples,
        {
          "no": no++,
          "id":"",
          "sampleUniqueNumber":samples.sampleUniqueNumber,
          "sampleName": "",
          "sampleReference": "",
          "purchaseContract": "",
          "salesContract": "",
          "purchaseGrade":"",
          "sampleType": "",
          "cupper": "",
          "totalSecore": "",
          "des": isEmpty(samples.cuppingResultAggregate.descriptors) ? "-" : makeComma(samples.cuppingResultAggregate.descriptors),
          "notes": isEmpty(samples.cuppingResultAggregate.internalNotes) ? "-" : samples.cuppingResultAggregate.internalNotes,
          "flag": "officialScoreDesInternalScore"
        }])
    })
  }

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        await getSamples(params.id)
      } finally {
        tempSamples.length = 0
        setTmpSample(samples)
        setLoading(false)
      }
    })()
  }, [])

  return (
    <Box mx={4}>
      <HeaderTitleWide
          title={t('report.cuppingSessionSnapshot')}
          //rightContent={renderButtons}
          sx={{mb: '0 !important'}}
          {...(isAuthenticated && {
            dataLinks: [
              {
                backUrl: sampleLink ,
                breadcrumb: t('common.backTo', { menu: t('menu.reviewSamples')}),
              },
              {
                backUrl: sampleInformationLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleInformation')}),
              },
              {
                backUrl: sampleReviewLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleReview')}),
              }
            ]
          })}
      />
        <TableContainer
          component={Paper}
          className={classes.table}
          sx={{mt: 5, mx:0}}
          >
          <Table
            size="small"
          >
            <TableHead>
              <TableRow>
                {checkReportConfiguration('sampleId') && <TableCell className={classes.tableHeader} >{t('report.id')}</TableCell>}
                {checkReportConfiguration('sampleName') && <TableCell className={classes.tableHeader} >{t('report.sampleName')}</TableCell>}
                {checkReportConfiguration('purchaseContract') && <TableCell className={classes.tableHeader} >{t('report.purchaseContract')}</TableCell>}
                {checkReportConfiguration('salesContract') && <TableCell className={classes.tableHeader} >{t('report.salesContract')}</TableCell>}
                {checkReportConfiguration('sampleReference') && <TableCell className={classes.tableHeader} >{t('report.sampleReference')}</TableCell>}
                {checkReportConfiguration('purchaseGrade') && <TableCell className={classes.tableHeader} >{t('report.purchaseGrade')}</TableCell>}
                {checkReportConfiguration('sampleType') && <TableCell className={classes.tableHeader} >{t('report.sampleType')}</TableCell>}
                <TableCell className={classes.tableHeader} >{t('report.cupper')}</TableCell>
                <TableCell className={classes.tableHeader} >{t('report.score')}</TableCell>
                <TableCell className={classes.tableHeader} >{t('report.descriptor')}</TableCell>
                <TableCell className={classes.tableHeader} >{t('report.notes')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tempSamples.map((data,i) => {
                return(
                  <TableRow sx={{backgroundColor: (data.flag === "officialScore" || data.flag === "officialScoreDesInternalScore") ? colors.primary.o25 : "white"}}>
                    {!isEmpty(data.id) &&
                      <>
                        {checkReportConfiguration('sampleId') &&<TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.id}</TableCell>}
                        {checkReportConfiguration('sampleName') && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.sampleName}</TableCell>}
                        {checkReportConfiguration('purchaseContract') && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.purchaseContractReference}</TableCell>}
                        {checkReportConfiguration('salesContract') && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.salesContractReference}</TableCell>}
                        {checkReportConfiguration('sampleReference') && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.sampleReference}</TableCell>}
                        {checkReportConfiguration('purchaseGrade') && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.purchaseGrade}</TableCell>}
                        {checkReportConfiguration('sampleType') && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.sampleType}</TableCell>}
                      </>
                    }
                    {data.flag === "officialScore" &&
                      <>
                        <TableCell className={clsx(classes.tableRow, data.flag === "officialScoreDesInternalScore" && 'row')} rowSpan={2}>
                            <Typography variant="body2" >{(data.cupper)}</Typography>
                        </TableCell>
                        <TableCell className={clsx(classes.tableRow, data.flag === "officialScoreDesInternalScore" && 'row')} rowSpan={2}>
                            <Typography variant="body2" sx={{color: colors.primary.main }}>{data.totalSecore}</Typography>
                        </TableCell>
                      </>
                    }
                    {data.flag === "cupper" &&
                      <>
                        <TableCell className={classes.tableRow}>
                            <Typography variant="body2" >{data.cupper}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableRow}>
                            <Typography variant="body2" sx={{color: colors.primary.main }}>{data.totalSecore}</Typography>
                        </TableCell>
                      </>
                    }
                    <TableCell className={classes.tableRow}>
                      <Typography variant="body2" sx={{color: data.flag === "officialScore" ? colors.text.primary :  colors.primary.main }}>{data.des}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableRow}>{data.notes}</TableCell>
                  </TableRow>
                  )
              })}
            </TableBody>
          </Table>
        </TableContainer>
    </Box>
  )
})

export default CuppingSessionsSnapshot
