import { REPORT_CONFIGURATION_STORAGE_KEY } from 'config/env';
import { loadString, saveString } from 'utils/storage';

const REPORT_CONFIGURATION_DATA = {
    cupping: {
        sampleId: true,
        purchaseGrade: true,
        purchaseContract: true,
        salesContract: true,
        cropYear: true,
        species: true,
        referenceNumber: true,
        cuppingDate: true,
        country: true,
        producer: true,
        density: true,
        trackingNumber: true,
        moisture: true,
        process: true,
        waterActivity: true,
        varietals: true
    },
    greenGrading: {
        author: true,
        purchaseGrade: true,
        purchaseContract: true,
        salesContract: true,
        color: true,
        weight: true,
        smell: true,
        moisture: true,
        waterActivity: true,
        roastColor: true,
        ovenTest: true,
        otaTest: true,
        glyphosateTest: true,
        density: true,
        yieldFactor: true
    },
    snapshot : {
        sampleId: true,
        purchaseContract: true,
        salesContract: true,
        sampleName: true,
        sampleReference: true,
        purchaseGrade: true,
        sampleType: true,
    }
}

export const getReportConfigurationData = async () => {
    const dataString = await loadString(REPORT_CONFIGURATION_STORAGE_KEY);
    if (!dataString) {
        await saveString(REPORT_CONFIGURATION_STORAGE_KEY, JSON.stringify(REPORT_CONFIGURATION_DATA));
        return REPORT_CONFIGURATION_DATA;
    }

    const { cupping: savedCuppingData, greenGrading: savedGreenGradingData, snapshot: savedSnapshotData } = JSON.parse(dataString);
    const mergedCuppingData = {};
    const mergedGreenGradingData = {};
    const mergedSnapshotData = {};

    for (const key in REPORT_CONFIGURATION_DATA.cupping) {
        mergedCuppingData[key] = savedCuppingData.hasOwnProperty(key) ? savedCuppingData[key] : REPORT_CONFIGURATION_DATA.cupping[key];
    }

    for (const key in REPORT_CONFIGURATION_DATA.greenGrading) {
        mergedGreenGradingData[key] = savedGreenGradingData.hasOwnProperty(key) ? savedGreenGradingData[key] : REPORT_CONFIGURATION_DATA.greenGrading[key];
    }

    for (const key in REPORT_CONFIGURATION_DATA.snapshot) {
        mergedSnapshotData[key] = savedSnapshotData.hasOwnProperty(key) ? savedSnapshotData[key] : REPORT_CONFIGURATION_DATA.snapshot[key];
    }

    return {
        cupping: mergedCuppingData,
        greenGrading: mergedGreenGradingData,
        snapshot: mergedSnapshotData
    };
}

export const saveConfigurationData = async (cupping, greenGrading, snapshot) => {
    const data = {
        cupping,
        greenGrading,
        snapshot
    };
    await saveString(REPORT_CONFIGURATION_STORAGE_KEY, JSON.stringify(data));
}

export const convertConfigurationData = async () => {
    const storeData = await getReportConfigurationData();
    return {
        cupping: Object.keys(storeData.cupping).map(config => ({
            label: config,
            value: storeData.cupping[config]
        })),
        greenGrading: Object.keys(storeData.greenGrading).map(config => ({
            label: config,
            value: storeData.greenGrading[config]
        })),
        snapshot: Object.keys(storeData.snapshot).map(config => ({
            label: config,
            value: storeData.snapshot[config]
        })),
    }
}